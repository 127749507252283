.baz-catlist-main{
    width: 100%;
    height: auto;
    padding-top: 30px;
    
}
@media (max-width: 1000px){
    .baz-catlist-main{
        width: 100%;
        height: auto;
        padding-top: 0;
        margin-left: 0;
    }
    .baz-catlist-top{
        width: 97%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        margin: 0 0 0 0;
        height: 80px;
        padding-left: 2.5%;
    }
    .baz-catlist-top::-webkit-scrollbar {
        display: none;
      }
    .baz-catlist-top-headline{
       padding: 0 0 15px 2.5%;
       margin:0;
       font-size: 16px;
    }
    
  }
 @media (min-width: 1000px){
    .baz-catlist-top{
        width: 93%;
        display: flex;
        height: auto;
        flex-flow: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 0 0 3.5%;
        height: 110px;
    }
    .baz-catlist-top-headline{
        padding: 0 0 0 3.5%;
        margin:0;
     }
    
 }