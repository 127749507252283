.baz-interestmain{
    width: 100%;
    height: auto;
    padding-top: 30px;
   
}
.baz-interest-login{
    padding: 10px 50px;
    background: rgb(7, 131, 104);
    color: white;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
}
.baz-interest-login:hover{
    background-color: rgb(6, 109, 87);
}
.baz-interest-0{
    width: 50%;
    height: 300px;
    background: white;
    border-radius: 5px;
    margin-left: 25%;
    text-align: center;
}
.baz-interest-0 h3{
    padding: 5px 0 0 0;
}
.baz-interests-product{
    width: 94%;
    margin-top: 20px;
    margin-left: 3.5%;
    height: auto;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
}
.baz-logout-interest{
    padding: 5% 0 15% 0;
    text-align: center;
}
.baz-logout-interest h2{
    margin: 0;
    padding: 0;
}
.baz-logout-interest p{
    margin: 5px 0 20px 0;
    padding: 0 35%;
}
@media (max-width: 1000px){
    .baz-interestmain{
        padding-top: 0;
       
    }
    .baz-interest-headline{
        font-size: 16px;
    }
    .baz-interest-0{
        width: 95%;
        height: 300px;
        margin-left: 2.5%;
    }
    .baz-suggest-list{
        width: 97%;
        height:210px;
        margin: 0;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        padding-left: 2.5%;
    }
    .baz-suggest-list::-webkit-scrollbar {
        display: none;
      }
    .baz-interests-product{
        width: 95%;
        margin:0 2.5%;
        height: auto;
        display: flex;
        flex-flow: wrap;
        flex-direction: row;
        justify-content: space-between;
    }
    .baz-suggest h3{
       padding-left: 2.5%;
       font-size: 16px;
    }
    .baz-interest-headline{
        margin: 0;
       padding: 0 0 15px 2.5%;
    }
    .baz-logout-interest{
        padding: 25% 0;
        text-align: center;
    }
    .baz-logout-interest p{
        margin: 5px 0 25px 0;
        padding: 0 10%;
    }
  }
 @media (min-width: 1000px){
    .baz-suggest-list{
        width: 95%;
        margin-left: 3.5%;
        height:auto;
        display: flex;
        flex-flow: wrap;
        flex-direction: row;
    }
    .baz-interest-headline{
        margin: 0;
        padding: 0 0 15px 3.5%;
     }
     .baz-suggest h3{
        padding-left: 3.5%;
     }
    
 }