.UserConfirmedOrders{
  width: 94%;
  margin-left: 2%;
}
.NoOrders{
  width: 100%;
  text-align: center;
}
.NoOrders h3{
  padding: 15% 0 10px 0;
}


@media (max-width: 1000px){
  .UserConfirmedOrders{
    width: 95%;
    margin-left: 2%;
  }
    .AllOrders{
        padding-bottom: 80px;
    }
    .NoOrders h3{
     padding: 55% 0 10px 0;
   }
   }
  @media (min-width: 700px){
     .AllOrders{
       width: 100%;
       display: flex;
       flex-flow: wrap;
       justify-content: space-between;
     }
  }
  