.FeedbackDeleteModal {
    position: fixed;
    z-index: 700;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    text-align: center;
    padding: 50px 0 30px 0;
}
.baz-feedback-delete-cancelbutton, .baz-feedback-delete-button{
     padding: 10px 25px;
     margin: 10px;
     cursor: pointer;
     border: none;
     border-radius: 5px;
     outline: none;
}
.baz-feedback-delete-cancelbutton{
    background: rgb(223, 220, 220);
}
.baz-feedback-delete-cancelbutton:hover{
    background: rgb(206, 204, 204);
}
.baz-feedback-delete-button{
    background: rgb(173, 6, 6);
    color: white;
}
.baz-feedback-delete-button:hover{
    background: rgb(150, 4, 4);
}
@media (max-width: 600px){
    .FeedbackDeleteModal {
        top:35%;
        width: 93%;
        height: 25vh;
        margin-left: 3.5%;
        border-radius: 0px;
    }
}
/* Mobile Only */
@media (min-width: 600px){
    .FeedbackDeleteModal{
        top:25%;
        width: 37%;
        height: 200px;
        right: 31%;
        border-radius: 5px;
        box-shadow: 2px 2px 8px 0px #a8a6a688;
    }
}
