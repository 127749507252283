.ControlProducts{
    width: 100%;
    height: auto;
}
.ProductsNav{
    width: 20%;
    height: 92vh;
    position: fixed;
    float: left;
    background: rgb(244, 248, 244);
}
.ProductStatus{
    width: 100%;
    height: 60px;
    background: green;
    /* margin: 10px 0; */
}
.ProductCategory{
    width: 100%;
    height: 67vh;
    overflow-y: scroll;
}
.ProductCategory li{
    width: 80%;
    margin: 10px;
    background: rgb(190, 194, 190);
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}
.ProductCategory li:hover{
    background: rgb(226, 230, 226);
}
.ProductsNav input{
    width: 70%;
    margin: 20px 8px;
    padding:10px;
}
.ProductsNav h5{
    padding: 15px 8px 4px 8px;
    margin: 0;
}
.ProductsNav ul{
    margin: 0;
    padding: 0;
}
.ProductsNav a{
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.ProductsNav button{
    outline: none;
    background: none;
    font-weight: bold;
    cursor: pointer;
    margin: 8px;
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
}
.AllProducts{
    width: 75%;
    float: right;
    margin-right: 30px;
}
.AllProducts table{
    width: 100%;
}
.AllProducts td{
    padding: 0 10px;
}