.baz-productview-1{
    margin: 0 0 15px 0;
    background: rgb(252, 252, 252);
    text-align: center;
    text-decoration: none;
    color: black;
    border-radius: 8px;
    overflow: hidden;
}
.baz-productview-1:hover{
    box-shadow: 2px 2px 8px 0px #9c9b9b;
}
.baz-productview-1-imagecontainer{
    width: 100%;
    height: 72%;
    text-align: center;
    padding: 10px 0;
    position: relative;
}
.baz-productview-1-imagecontainer-details{
    width: 100%;
    height: 25%;
}
.baz-productview-1 img{
    max-width: 100%;
    max-height: 100%; 
    /* top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
     position: absolute; */
}

.baz-productview-1 h3{
    margin: 0;
    padding: 0;
    display: inline-block;
    overflow-y: hidden;
 
}
.baz-productview-1 h4{
    margin: 0;
    padding: 0;
}
.baz-productview-1 p{
    margin: 0;
    padding: 5px 0;
}
.baz-product1-offerprice{
    text-decoration: line-through;
    font-size: 12px;
    color: rgb(105, 104, 104);
}
.baz-product1-offerprice{
    text-decoration: line-through;
    font-size: 14px;
}
.LoadingImageStyle{
    width: 100%;
    height: 100%;
    background-color: red;
}
@media (max-width: 500px){
    .baz-productview-1:hover{
        box-shadow: none;
    }
    .baz-productview-1{
        width:48%;
        height: 240px;
        border-radius: 5px;
    }
    .baz-productview-1 p{
        margin: 3px 0;
        padding: 0;
        /* font-size: 15px;
        font-weight: 600; */
    }
    .baz-productview-1-imagecontainer{
        width: 100%;
        height: 65%;
        text-align: center;
        padding: 10px 0;
    }
    .baz-product1-price{
        font-size: 15px;
        font-weight: 500;
        color:rgb(6, 109, 87);
    }
    .baz-product1-offerprice{
        text-decoration: line-through;
        font-size: 12px;
        color: rgb(105, 104, 104);
    }
  }
  @media (min-width: 501px) and (max-width: 999px){
    .baz-productview-1{
        width:48%;
        height: 270px;
        border-radius: 2px;
    }
    .baz-productview-1-imagecontainer{
        width: 100%;
        height: 65%;
        text-align: center;
        padding: 10px 0;
    }
  }
 @media (min-width: 1000px){
    .baz-productview-1{
        width:24%;
        height: 350px;
    }
   
 }
 