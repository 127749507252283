.UserFeedBack{
    width: 48.5%;
    height: 380px;
    background: rgb(255, 255, 255);
    margin: 0 0 20px 0;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    text-decoration: none;
}
.FUser{
    width: 95%;
    height: 17%;
    margin-top: 2%;
    margin-left: 2.5%;
}
.FUserPic{
    width: 50px;
    height: 50px;
    background:rgb(6, 109, 87);
    border-radius: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: "center"
}
.FUserD{
    width: 82%;
    height: 100%;
    float: right;
}
.FText{
    width: 95%;
    height: 32%;
    margin-left: 2.5%;
}
.FProduct{
    width: 95%;
    height: 35%;
    background: rgb(235, 233, 235);
    margin: 5% 0 0 2.5%;
    border-radius: 5px;
}
.FProduct:hover{
    background: rgb(202, 201, 201);
}
.FProductContainer{
   width: 40%;
   height: 100%;
   float: left;
   text-align: center;
}
.FProductContainer img{
    max-height: 100%;
    max-width: 100%;
}
.FProductD{
    width: 55%;
    height: 100%;
    float: right;
}
@media (max-width: 1000px){
    .UserFeedBack{
        width: 100%;
    }
    .FProductD h4{
        font-size: 15px;
    }
  }
 @media (min-width: 1000px){
  
 }
 