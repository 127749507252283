.baz-single-cat{
    width: 93%;
    height: auto;
    margin: 30px 0 0 3.5%;
    cursor: pointer;
}

@media (max-width: 600px){
  .baz-single-cat{
    margin: 10px 0 0 3.5%;
}
.baz-single-cat{
    width: 100%;
    height: auto;
    margin: 10px 0 0 0;
    cursor: pointer;
    padding-bottom: 10px;
}
    .baz-single-cat h3{
        margin-left: 2.5%;
        font-size: 16px;
    }
  .baz-single-cat-details{
    width: 97%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    height: 180px;
    padding-left: 2.5%;
  }
  .baz-single-cat-details::-webkit-scrollbar {
    display: none;
  }
 }
 @media (min-width: 601px) and (max-width: 999px){
  .baz-single-cat{
    margin: 10px 0 0 3.5%;
}
.baz-single-cat{
    width: 100%;
    height: auto;
    margin: 10px 0 0 0;
    cursor: pointer;
    padding-bottom: 10px;
}
    .baz-single-cat h3{
        margin-left: 2.5%;
        font-size: 16px;
    }
  .baz-single-cat-details{
    width: 97%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    height: 200px;
    padding-left: 2.5%;
  }
  .baz-single-cat-details::-webkit-scrollbar {
    display: none;
  }
}
 @media (min-width: 1000px){
   .baz-single-cat-details{
       width: 102%;
       display: flex;
       flex-flow: wrap;
       flex-direction: row;
   }

 }