.CatOff{
    width: 19%;
    height: 220px;
    background: #ffffff;
    border-radius: 5px;
    margin: 0 0 15px 0;
    animation: mymove 1s infinite;
}
 @media (max-width: 1000px){
    .CatOff{
        width: 48%;
        height: 180px;
        margin: 0 0 15px 0;
    }
  }
 @media (min-width: 1000px){
   
 } 