.baz-SignUp{
    width: 35%;
    height: 70vh;
    /* background: white; */
    margin-top: 50px;
    margin-left: 32%;
    border-radius: 5px;
    /* box-shadow: 2px 2px 8px 0px #888888; */
}
.DisplayNone{
    display: none;
}
.SignUpMainButton, .SignUpSubButton{
    width: 87%;
    padding: 10px 0;
    margin: 20px 0 0 0;
    border-radius: 5px;
    background-color:rgb(7, 131, 104);
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    outline: none;
}
.SignUpSubButton:hover{
    cursor:not-allowed;
}
.SignUpMainButton:hover{
    background-color: rgb(6, 109, 87);
    cursor: pointer;
}
.baz-signin-phone input[type=number]::-webkit-inner-spin-button, 
.baz-signin-phone input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

.baz-signin-phone input[type=number] {
  -moz-appearance: textfield;
}
@media (max-width: 700px){
    .baz-SignUp{
        width: 100%;
        height: 80vh;
        margin-top: 0;
        margin-left: 0;
        box-shadow: none;
        background: none;
    }
    
   .SignUpMainButton, .SignUpSubButton{
       width: 87%;
   }
 }

 @media (min-width:701px) and (max-width: 1100px){
     .baz-SignUp{
         width: 50%;
         height: 75vh;
         margin-left: 22%;
         margin-top: 20px;
     }
 }