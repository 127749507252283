.AdminCheckModal {
    position: fixed;
    z-index: 7000;
    box-sizing: border-box;
    top: 0px;
    height: 83vh;
    width: 90%;
    height: 80vh;
    background: rgb(255, 255, 255);
    left: calc(5%);
    top: 10%;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 0px rgba(112, 110, 110, 0.795);
}

/* @media (max-width: 600px){
    .CartModal {
        width: 100%;
        height: 100vh;
        left: calc(0);
        width: 100%;
        background: rgb(255, 255, 255);
    }
} */
/* Mobile Only */
@media (min-width: 800px){
    .AdminCheckModal{
        width: 500px;
        height: 80vh;
        top: 60px;
        left: calc(32%);
        border-radius: 5px;

    }
}
