.Home{
    width: 100%;
    /* width: 93%;
    margin-left: 3.5%; */
    height: auto;
    padding-top: 10px;
}
.header-img{
    width: 93%;
    height: 350px;
    margin: 20px 0 0 3.5%;
    border-radius: 5px;
}
/* style={{width: "93%", height: "500px", margin:"20px 0 0 3.5%", borderRadius: "5px"}} */
.RecommendedProducts{
    width: 93%;
    margin-left: 3.5%;
    height: auto;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
}
.baz-allsub-cat-home{
    width: 93%;
    margin-left: 3.5%;
    height: auto;
    margin-top: 30px;
}
.baz-allsub-cat-home h3{
    float: left;
    margin: 0;
    padding: 0;
}
.baz-user-collect{
    width: 93%;
    margin: 3.5%;
    height: 300px;
    background-color: rgb(255, 255, 255);
}
.baz-seeall-subcategories{
    float: right;
    padding: 10px 20px;
    color: black;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
}
.baz-seeall-subcategories:hover{
    background: rgb(206, 203, 203);
}
@media (max-width: 1000px){
    .Home{
       width: 100%;
      height: auto;
      padding-top: 0;
      margin-left: 0;
   }
   .header-img{
    width: 93%;
    height: 250px;
    margin: 0 0 0 3.5%;
    border-radius: 5px;
}
    .baz-home-categories{
        width: 95%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        margin:5px 0 0 0;
        height: 90px;
        padding:0 3% 0 2%;
    }
    .baz-home-categories::-webkit-scrollbar {
        display: none;
      }
    .RecommendedProducts{
        width: 95%;
        margin: 0 2.5%;
    }
    .baz-reco-headline{
        padding: 5px 0 10px 2.5%;
        margin: 0;
        font-size: 16px;
    }
   .baz-allsub-cat-home{
      margin-top: 5px;
    }
    .baz-allsub-cat-home h3{
        font-size: 14px;
    }
    .baz-all-sub{
       display: none;
    }
    
    .baz-allsub-cat-home{
        width: 100%;
        margin-left: 0;
        height: auto;
    }
    .mobile-sub-home{
        width: 97%;
        padding-left: 2.5%;
        height: 200px;;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
    .mobile-sub-home::-webkit-scrollbar {
        display: none;
      }

  }
 @media (min-width: 1000px){
   .baz-home-categories{
        width: 93%;
        margin-left:3.5%;
        display: flex;
        justify-content: space-between;
        height: 110px;
      
   }
   .baz-all-sub{
        width: 100.5%;
        height: 200px;
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        justify-content: space-between;
   }
   .baz-reco-headline{
        padding-left: 3.5%;
    }
    .mobile-sub-home{
        display: none;
    }
 
 } 
 @media(min-width: 1600px){
    .RecommendedProducts{
        width:70%;
        margin-left:14%;
    }
    .baz-allsub-cat-home{
        width:70%;
        margin-left:14%;
    }
    .baz-home-categories{
        width:70%;
        margin-left:14%;
   }
   .baz-reco-headline{
        padding-left: 14%;
   }
}
@media(min-width: 2400px){
    .RecommendedProducts{
        width:40%;
        margin-left:30%;
    }
    .baz-allsub-cat-home{
        width:40%;
        margin-left:30%;
    }
    .baz-home-categories{
        width:40%;
        margin-left:30%;
   }
   .baz-reco-headline{
        padding-left: 30%;
    }
}
