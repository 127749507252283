.baz-0-Related:hover{
    box-shadow: 2px 2px 8px 0px #9c9b9b;
}
.baz-RelImageContainer{
    width: 100%;
    height: 75%;
    text-align: center;
    overflow: hidden;
    position: relative;
    margin: 5px 0;
}
.baz-RelImageContainer img{
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.baz-related-product-price{
    padding: 0;
    margin: 0;
    text-align: center;
}

@media (max-width: 1000px){
    .baz-0-Related{
        width: 60%;
        height: 240px;
        background: rgb(255, 255, 255);
        display: inline-block;
        margin-right: 10px;
        text-decoration: none;
        color: black;
        border-radius: 2px;
        padding: 1% 0 0 0;
    }
    .baz-related-product-price{
        font-size: 15px;
        font-weight: 500;
        color:rgb(6, 109, 87);
    }
     .baz-RelImageContainer{
       height: 68%;
    }
  }
 @media (min-width: 1000px){
    .baz-0-Related{
        width: 24%;
        height: 350px;
        background: rgb(255, 255, 255);
        margin:0 1% 15px 0;
        text-decoration: none;
        color: black;
        border-radius: 5px;
        padding: 1% 0 0 0;
    }
   
 }