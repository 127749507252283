.baz-MenuModal {
    position: fixed;
    z-index: 7000;
    box-sizing: border-box;
    top: 0px;
    height: 100vh;
    width: 80%;
    background: rgb(255, 255, 255)
   
}
@media (min-width: 1101px){
    .baz-MenuModal{
        width: 370px;
        height: 83vh;
        margin-top: 60px;
        right: 3.5%;
        box-shadow: 2px 2px 8px 0px #888888;
        border-radius: 5px;
        overflow-y: scroll;
    }
    .baz-MenuModal::-webkit-scrollbar {
        display: none;
      }
}
