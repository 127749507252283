.SearchMain{
    width: 90%;
    margin: 0;
    padding: 30px 0 20px 3.5% ; 
    color: rgb(97, 95, 95);
}
.SearchMain span{
    margin: 0 5px;
    font-style: italic;
    color: rgb(18, 141, 125);
}
.baz-not-found-message{
    padding: 0 35%;
}
@media (max-width: 1000px){
    .SearchMain{
        width:95%;
        margin-left: 2.5%;
        margin-right: 0;
        padding: 0; 
        display: inline-block;
    }
    .baz-not-found-message{
        padding: 0 5%;
    }
  }
