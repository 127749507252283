.baz-footer{
    width: 100%;
    height: 300px;
    background: rgb(255, 255, 255);
    margin-top: 80px;
    padding: 30px 0 0 0;
    border-top: 1px solid #eeee;
   
}
.baz-footer-ul{
    width: 93%;
    height: 100%;
    margin-left: 3.5%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;

}
.baz-footer-part1{
    width: 29%;
    height: 100%;

}
.baz-footer-part2{
    width: 20%;
    height: 100%;
}

.baz-footer-appicon img{
    width: 115px;
    height: 30px;
    margin-bottom: 5px;
}
.baz-footer-social-link{
    float: right;
}
.baz-footer-social-link img{
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 10px;
}
.baz-footer li{
    list-style: none;
    padding: 10px 0;
}
.baz-footer a{
    text-decoration: none;
    color: rgb(44, 43, 43);
}
.baz-footer a:hover{
    color: rgb(6, 119, 95);
}
.baz-footer-bottom{
    width:100%; 
    height:30px;
    background: rgb(77, 74, 74);
    padding:15px 0;
}
@media (max-width: 1000px){
    .baz-footer{
        display: none;
    }
  }
 @media(min-width: 1600px){
    .baz-footer-ul{
        width:70%;
        margin-left:14%;
    }
    .baz-footer-bottom{
        width:70%;
        margin-left:14%;
    }
}
@media(min-width: 2400px){
    .baz-footer-ul{
        width:40%;
        margin-left:30%;
    }
    .baz-footer-bottom{
        width:40%;
        margin-left:30%;
    }
}