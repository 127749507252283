.Control-categories{
    width: 50%;
    height: 75vh;
    margin-left: 25%;
    overflow-y: scroll;
}
.cat-manage{
    width: 100%;
    height: 100%;
    border-bottom: 2px solid gray;
    padding: 10px 0;
}
.cat-manage img{
    width: 100px;
    height: 100px;
   
}
.subcat-manage{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: wrap;
    justify-content:space-between;
}
.subcat-main-single{
  width: 150px;
  height: 150px;  
  background-color: green; 
  margin: 10px 0;  
}
.addmore-subcat{
    width: 150px;
    height: 150px;  
    background-color: rgb(12, 5, 112); 
    margin: 10px 0;
}