/* .signin-2022{
    background: yellow;
    text-align: center;
} */


/* before 2022 */
.baz-SignIn{
    width: 35%;
    height: 65vh;
    /* background: white; */
    margin-top: 50px;
    margin-left: 32%;
    border-radius: 5px;
    /* box-shadow: 2px 2px 8px 0px #888888; */
}
.baz-signin-logo{
    width: 100px;
    height: 30px;
    margin-top: 35px;
}
.baz-signin-phone{
    width: 85%;
    height:40px;
    padding: 0 5px;
   
    border-radius: 5px;
    margin: 0 0 10px 0;
    margin-left: 6%;
    background: none;

}
.baz-signin-phone input{
    width: 100%;
    padding:11.5px 5px;
    margin: 0 0 15px 0;
    border:none;
    font-size: 15px;
    outline: none;
    border: 1px solid rgb(223, 222, 222);
}
.baz-signin-pass{
    width: 85%;
    height:40px;
    padding: 0 5px;
    border: 1px solid rgb(223, 222, 222);
    border-radius: 5px;
    margin: 0 0 10px 0;
    margin-left: 6%;
    background: white;
}
.baz-signin-pass input{
    width: 70%;
    padding:11px 2px;
    margin: 0 0 15px 3px;
    border:none;
    font-size: 15px;
    outline: none;
    background: none;
}
.baz-signin-pass button{
    padding: 3% 4%;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: gray;
    float: right;
}
.login-text{
    font-weight: 700;
    font-size: 50px;
}
.baz-signin-button{
    width: 87%;
   padding: 10px 0;
   margin:10px 0;
   cursor: pointer;
   border-radius: 5px;
   background-color:rgb(7, 131, 104);
   color: white;
   border: none;
   outline: none;
   font-size: 16px;
   font-weight: bold;
}
.baz-signin-button:hover{
    background-color: rgb(6, 109, 87);
}
@media (max-width: 700px){
   .baz-SignIn{
       width: 100%;
       height: 80vh;
       margin-top: 0;
       margin-left: 0;
       box-shadow: none;
       background: none;
   }
   .baz-signin-logo{
    width: 100px;
    height: 30px;
    margin-top: 60px;
  }
  .baz-signin-button{
     width:87%;
  }
  .login-text{
    font-weight: 700;
    font-size: 30px;
}
}
@media (min-width:701px) and (max-width: 1100px){
    .baz-SignIn{
        width: 50%;
        height: 60vh;
        margin-left: 22%;
        margin-top: 20px;
    }
}
