.baz-OrderViewModal {
    position: fixed;
    z-index: 700;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
}

@media (max-width: 750px){
    .baz-OrderViewModal {
        width: 100%;
        height: 100vh;
        left: calc(0%);
        top: 0;
    }
}

@media (min-width: 750px){
    .baz-OrderViewModal{
        top:56px;
        width: 50%;
        height: 85vh;
        left: calc(25%);
        border-radius: 5px;
        box-shadow: 2px 2px 8px 0px #a8a6a688;
    }
}
