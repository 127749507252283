.ProcessingModal {
    position: fixed;
    z-index: 700;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    text-align: center;
}

@media (max-width: 600px){
    .ProcessingModal {
        top:25%;
        width: 95%;
        height: 50vh;
        margin-left: 2.5%;
        border-radius: 0px;
    }
    .ProcessingModal h1{
        padding-top: 30%;
        color: rgb(15, 141, 141);
        font-size: 45px;
    }
    .ProcessingModal h2{
        padding-top: 35%;
        color: rgb(15, 141, 141);
        font-size: 25px;
    }
}
/* Mobile Only */
@media (min-width: 601px){
    .ProcessingModal h1{
        padding-top: 17%;
        color: rgb(15, 141, 141);
        font-size: 45px;
    }
    .ProcessingModal h2{
        padding-top: 20%;
        color: rgb(15, 141, 141);
        font-size: 25px;
    }
    .ProcessingModal{
        top:25%;
        width: 40%;
        height: 45vh;
        right: 31%;
        border-radius: 5px;
        box-shadow: 2px 2px 8px 0px #a8a6a688;
    }
}
