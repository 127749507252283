.BazCategory{
    height: auto;
    text-align: center;
    text-decoration: none;
    color: black;
}
.BazCategory:hover{
    color: rgb(25, 139, 105);
}
.BazCategory p{
    margin: 3px 0;
    padding: 0;
    text-transform: uppercase;
    /* font-weight: 500; */
    font-size: 15px;
}
@media (max-width: 1000px){
    .BazCategory{
        width:27%;
        margin-right: 10px;
        display: inline-block;
        background: none;
    }
    .BazCategory img{
        width: 50px;
        height: 50px;
    }
    .BazCategory p{
        margin: 0;
        padding: 0;
        font-size: 13px;
    }
  }
 @media (min-width: 1000px){
   .BazCategory{
       width: 15%;
       height: auto;
   }
   .BazCategory img{
    width: 60px;
    height: 60px;
    margin-top: 20px;
}
 }
 