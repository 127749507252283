.Cart{
    width: 100%;
    height: 100%;
}
.EmptyCart{
   width: 100%;
   height: 100%;
}
.EmptyCart img{
   width: 50%;
   height: 50%;
   margin-top: 40%;
}
.ClearAllButton{
    float: right;
    margin:18px 15px;
    border: none;
    outline: none;
    font-weight: bold;
    color: rgb(7, 131, 104);
    background: none;
    cursor: pointer;
}
.ClearAllButton:hover{
    color: rgb(199, 70, 11);
}

.login-to-bag{
    background:rgb(7, 131, 104);
    padding: 10px 50px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
}
.login-to-bag:hover{
    background: rgb(6, 109, 87);
}

.CartCheckOut{
    width: 25%;
    height: 100%;
    float: right;
    text-align: center;
    padding: 0 2%;
}
.CheckOutLink{
    padding: 12px 50px;
    background: rgb(7, 131, 104);
    color: white;
    text-decoration: none;
    font-weight:bold;
    border-radius: 25px;
    border: none;
    outline: none;
    cursor: pointer;
}
.CheckOutLink:hover{
    background: rgb(6, 109, 87);
}
.AddMoreButton{
    padding: 10px 30px;
    margin: 0 0 20px 38%;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid rgb(224, 221, 221);
    outline: none;
    border-radius: 5px;
    background: rgb(241, 240, 240);
}
.AddMoreButton:hover{
    border:1px solid rgb(5, 129, 108);
    color: rgb(5, 129, 108);
    background: white;
}
.MobileCheckout{
    width: 100%;
    height: 70px;
    background: rgb(255, 255, 255);
    border-top: 1px solid #eee;
}
.EmptyCart button{
    padding: 12px 60px;
    background: rgb(11, 145, 138);
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    outline: none;
}
.EmptyCart button:hover{
    background: rgb(7, 121, 115);
}
.baz-logout-bag-headline{
    margin: 0;
    padding: 50% 0 20px 0;
}
.CartProductsAll{
    width: 100%;
    height: 90%;
    overflow-y: scroll;
    left: 0;
    overflow-x: hidden;
    padding-top: 15px;
    /* background: rgb(236, 235, 236); */
}
.CartProductsAll::-webkit-scrollbar {
    display: none;
  }
 @media (max-width: 1000px){
    .CartCheckOut{
        display: none;
    }
    
    .CloseButton{
        border: 1px solid rgb(201, 198, 198);
        float: right;
        margin: 10px;
        padding: 10px 13px;
        border-radius: 100%;
        outline: none;
    }
    .baz-cart-mobile-back{
       font-size: 20px;
       margin: 0 10px;
    }
    .EmptyCart img{
        width: 80%;
        height: 80%;
        margin-top: 40%;
     }
    .EmptyCart h3{
        margin: 0;
        padding: 10% 0 10px 0;
    }
    .baz-logout-bag-headline{
        padding: 40% 0 20px 0;
    }
    .CartProductsAll{
        height: 86%;
    }
  }
 @media (min-width: 1000px){
    
    /* .MobileCheckout{
        display: none;
    } */
    .CloseButton{
        display: none;
    }
    .baz-cart-mobile-back{
        display: none;
    }
    .EmptyCart h3{
        margin: 0;
        padding: 5% 0 10px 0;
    }
    
 }
 