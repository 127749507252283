.CProduct{
    width: 100%;
    height: 100px;
    background: rgb(241, 238, 241);
    margin: 10px 0;
}
.CProduct img{
    width: 30%;
    height: 100px;
    float: left;
    margin-right: 10px;
}
.CProduct h5{
    padding-top: 30px;
    margin: 0;
}