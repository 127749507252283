.NotificationModal {
    position: fixed;
    z-index: 7000;
    box-sizing: border-box;
    top: 0px;
    width: 100%;
    height: 100vh;
    background: rgb(255, 255, 255);
   
}

@media (min-width: 700px){
    .NotificationModal{
        width: 370px;
        height: 87vh;
        margin-top: 60px;
        right: 3.5%;
        box-shadow: 2px 2px 8px 0px #888888;
        border-radius: 5px;
        z-index: 7000;
    }
}
/* @media (min-width: 700px) and (max-width: 1300px){
    .NotificationModal{
        width: 370px;
        height: 80vh;
        margin-top: 60px;
        left: calc(64%);
        box-shadow: 2px 2px 8px 0px #888888;
        border-radius: 5px;
        z-index: 7000;
    }
} */
/* @media (min-width: 923px) and (max-width:1079px){
    .NotificationModal{
        width: 370px;
        height: 80vh;
        margin-top: 60px;
        left: calc(58%);
        box-shadow: 2px 2px 8px 0px #888888;
        border-radius: 5px;
        z-index: 7000;
    }
}
@media (min-width: 700px) and (max-width:922px){
    .NotificationModal{
        width: 370px;
        height: 80vh;
        margin-top: 50px;
        left: calc(45%);
        box-shadow: 2px 2px 8px 0px #888888;
        border-radius: 5px;
        z-index: 7000;
    }
} */

