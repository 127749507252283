.baz-collectionview2{
    width: 93%;
    height: auto;
    margin: 20px 0 40px 3.5%;
}
.baz-collectionview2 h3{
    float: left;
    margin: 0;
    padding: 5px 0;
    font-size: 22px;
}
.baz-collection2-header{
    width: 100%;
    height: 55px;
    margin-bottom: 5px;
}
.baz-collectionview2-seeall-link{
    float: right;
    padding: 10px 20px;
    text-decoration: none;
    color: rgb(66, 64, 64);
    font-size: 18px;
    border-radius: 5px;
}
.baz-collectionview2-seeall-link:hover{
    background: rgb(178, 180, 178);
}


.Switcher{
    width:93%;
    height: 10px;
    position: absolute;
}

.Switcher button:hover{
    background: rgb(243, 241, 241);
    
}
.BackSwitcher{
    margin: 11.5% 0px;
    padding: 14px 19px;
    border-radius: 100%;
    background: white;
    font-size: 20px;
    border: 1px solid #cccc;
    outline: none;
    cursor: pointer;
    box-shadow: 2px 4px 5px 1px  rgb(139, 136, 136);
    color: rgb(107, 106, 106);
    float: left;
    z-index: 700;
}
.ForwardSwitcher{
    margin: 11.5% 0px;
    padding: 14px 19px;
    border-radius: 100%;
    background: white;
    font-size: 20px;
    border: 1px solid #cccc;
    outline: none;
    cursor: pointer;
    box-shadow: 2px 4px 5px 1px  rgb(139, 136, 136);
    color: rgb(107, 106, 106);
    float: right;
}
.DisplayNone{
    display: none
}

@media (max-width: 1000px){
    .baz-collectionview2{
        width: 100%;
        height: auto;
        margin: 20px 0;
        /* padding: 20px 0; */
    }
    .Switcher{
        display: none;
    }
.collectionview2-products-all{
   display: none;
}
.collectionview2-products-all-mobile{
    width: 97.5%;
    height: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding-left: 2.5%;
}
.collectionview2-products-all-mobile::-webkit-scrollbar {
    display: none;
  }
  .baz-collection2-header{
    display: none;
  }
  .baz-collectionview2 h3{
   font-size: 15px;
}
}
@media (min-width: 1000px){
    .collectionview2-products-all{
        width: 100%;
        height: auto;
        /* background: red; */
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        justify-content: space-between;
        /* margin-left: 2%; */
    }
    .collectionview2-products-all-mobile{
        display: none;
    }
}

@media(min-width: 1600px){
    .baz-collectionview2{
        width:70%;
        margin-left:14%;
    }
    .Switcher{
        display: none;
    }
   
}
@media(min-width: 2400px){
    .baz-collectionview2{
        width:40%;
        margin-left:30%;
    }
    .Switcher{
        display: none;
    }
}

