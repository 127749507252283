.DekstopNav{
    width: 100%;
    height: 60px;
    background-color: white;
    border-bottom:1px solid rgb(226, 224, 224);
    position: fixed;
    z-index: 70;
    box-shadow: 1px 1px 5px 0px rgb(228, 227, 227);
}
.DekstopNavCanvas{
    width:93%;
    margin-left:3.5%;
}
.BottomNav{
    width: 100%;
    height: 50px;
    border-top: 1px solid rgb(190, 188, 188);
    bottom: 0;
    position: fixed;
    background: white;
}
.LayoutContent{
    /* width: 93%;
    height: auto;
    padding-top: 85px;
    margin-left: 3.5%; */
    width: 100%;
    height: auto;
    padding-top: 55px;
}
/* @media (max-width: 1000px){
    
  }
 @media (min-width: 1000px){
   
 } */
 .DekstopNavRightOptions{
    width: 87%;
    height: 100%;
    float: right; 
 }

 .DekstopNavRightOptions ul{
    height: 100%;
    margin: 0;
    padding: 0;
    float:left;
}
.DekstopNavRightOptions li{
    display: inline-block;
    margin: 5px 0; 
}

.DekstopNavRightOptions a{
    text-decoration: none;
    padding: 10px 10px 16.5px 10px;
    color: rgb(107, 106, 106);
    font-weight: 500;
}
.DekstopNavRightOptions a:hover{
    color: rgb(5, 156, 136);
}
.DekstopNavRightOptions span{
    margin-right: 5px;
}
.SearchBox{
    width: 40%;
    float: right;
    height: 100%;
    border-radius: 3px;
    margin-top: 1px;
    background: #EEF3F8;
}
.SearchBox input{
    width: 85%;
    padding: 11px 2% 11px 0;
    outline: none;
    border: none;
    outline: none;
    float: right;
    font-size: 15px;
    background: none;
}
.SearchBox button{
    border: none;
    background: none;
    font-size: 17px;
    margin: 10px 2px 0 0;
}
.NavRightAuthOptions{
    float: right;
    margin-top: 10px;
}
/* style={{borderRadius:"100%",padding:"10px",outline:"none", border:"none", fontWeight:"bold",fontSize:"20px"}} */
.NavRightAuthOptions button{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    font-weight: bold;
    border: none;
    margin: 0 0 0 10px;
    outline: none;
    font-size: 17px;
    cursor: pointer;
}
.NavRightAuthOptions button:hover{
    color: rgb(5, 156, 136);
    background: rgba(5, 156, 136, 0.212);
}
.baz-Badge{
  position: absolute;
  top: 5px;
  padding: 4px 8px;
  border-radius: 50%;
  background-color: rgb(13, 165, 145);
  color: white;
  font-size: 12px;
}
.baz-Badge-mobile{
    position: absolute;
    top: 8px;
    padding: 3px 7px;
    border-radius: 50%;
    background-color: rgb(13, 165, 145);
    color: white;
    font-size: 12px;
}

.baz-skeleton-cart-left-view{
    width: 96%;
    height:140px;
    /* background:#EFF3EF; */
    margin:5px 2%;
    /* animation: mymove 1s infinite; */
}

.baz-access-link{
    padding: 5px;
    color: black;
    text-decoration: none;
}
.baz-access-link:hover{
    color: rgb(15, 121, 103);
    text-decoration: underline;
}
.CheckOutHeader{
    width: 100%;
    height: 56px;
    border-bottom: 1px solid #eee;
}
.CheckOutHeader h3{
    width: 55%;
    padding: 17px 20px;
    margin: 0;
    float: left;
}
.CheckOutHeader button{
    float: right;
    padding: 10px 13px;
    border-radius: 100%;
    cursor: pointer;
    outline: none;
    border: 1px solid #eee;
    margin: 10px 20px;
}
.CheckOutHeader button:hover{
    background: rgba(21, 148, 131, 0.253);
    color: rgb(17, 148, 126);
}
.CheckDel{
    width: 85%;
    height: 87%;
    margin-left: 7%;
}
.CheckDel h5{
    margin: 0;
    padding: 10px 0 5px 0;
}
.SelectButton{
    width: 99%;
    padding:10px 0;
    margin: 0 0 10px 0;
    cursor: pointer;
    font-size: 16px;
    background: white;
    border: 1px solid rgba(228, 224, 224, 0.933);
    outline: none;
    border-radius: 5px;
    color: rgb(56, 54, 54);
}
.CheckDel input{
    width: 94.2%;
    padding: 12px 10px;
    border: 1px solid rgba(228, 224, 224, 0.933);
    outline: none;
    border-radius: 5px;
    font-size: 14px;
}
.CheckDel textarea{
    width: 94.7%;
    padding: 10px;
    height: 50px;
    border: 1px solid rgba(228, 224, 224, 0.933);
    outline: none;
    border-radius: 5px;
    resize: none;
    font: inherit;
    font-size: 14px;
}
.NextButton,.NextButton2{
    background: rgb(7, 131, 104);
    color: white;
    float: right;
    padding:10px 50px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    outline: none;
    border-radius: 5px;
    margin: 10px 2px;
}
.NextButton:hover{
    background: rgb(6, 109, 87);
}
.CheckPay{
    width:85%;
    height: 87%;
    margin-left: 7%;
}
.BackButton{
    padding:10px 20px;
    outline: none;
    border: 1px solid #eee;
   outline: none;
   border-radius: 5px;
   cursor: pointer;
}
.BackButton:hover{
    background: rgb(202, 200, 200);
}
.PlaceOrder{
    padding: 10px 35px;
    background:rgb(7, 131, 104);
    border: 1px solid rgb(6, 155, 147);
    color: white;
    margin: 0 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
    outline: none;
}
.PlaceOrder:hover{
    background: rgb(6, 109, 87);
}
.CheckPay img{
    width: 90%;
    height: 130px;
    text-align: center;
}
.DisplayNone{
    display: none;
}
.SearchSugguest{
    width: 84%;
    padding: 10px;
    height: 73vh;
    background: rgb(255, 255, 255);
    border: 1px solid #eeee;
    box-shadow: 2px 2px 8px 0px #888888;
    z-index: 7000;
    overflow-y: scroll;
}
.SearchSugguest::-webkit-scrollbar {
    display: none;
  }
.SearchSugguest li, .MobileSuggest li{
    width: 94.5%;
    padding: 10px;
    margin: 0 0 5px 0;
    cursor: pointer;
    border-radius: 5px;
    list-style: none;
}
.SearchSugguest li:hover{
    background: #F0F2F5;
}
.SearchSugguest a, .MobileSuggest a{
    padding-right: 20%;
    text-decoration: none;
    color: rgb(80, 78, 78);
}
.MobileSuggest{
    width:90%;
    height: 90%;
    margin: 0 5%;
    overflow-y: scroll;
}
.MobileSuggest::-webkit-scrollbar {
    display: none;
  }
.MobileSuggest li:hover{
    background: #F0F2F5;
}
.MobileSuggest a{
    padding-right: 20%;
}
.BottomNav ul{
    height: 100%;
    margin: 0;
    padding: 0;
}
.BottomNav li{
    display: inline-block;
    margin: 0; 
}
.baz-mobile-right-options{
    width: 53%;
    height: 100%;
    float: right;
}
.baz-mobile-right-options button{
    float: right;
    font-size: 20px;
    border: none;
    outline: none;
    background: none;
    margin: 13px 0 0 15px;
    color: rgb(117, 114, 114);
}
.baz-chcekout-form{
    width: 100%;
    height: 80%;
    overflow-y: scroll;
    padding-bottom: 30px;
}
.baz-cart-header{
    width: 100%;
    height: 7%;
    border-bottom: 1px solid #eee;
    padding: 1% 0;
}
.baz-cart-body{
    width: 100%;
    height: 85%;
}
.baz-cart-mobile-back-2{
    margin: 0 0 0 10px;
    cursor: pointer;
}
.baz-cart-mobile-back-2:hover{
    color: green;
}
@media (max-width: 1100px){
    .MobileNav{
        width: 100%;
        height: 50px;
        background-color: white;
        border-bottom: 1px solid rgb(199, 199, 199);
        position: fixed;
        z-index: 70;
       
    }
   .SearchSugguest{
       display: none;
   }
    .baz-skeleton-cart-left-view{
      height: 100px;
    }
    
    .DekstopNav{
        display: none;
    }
    .baz-cart-header{
        height: 50px;
    }
    .baz-cart-body{
        height: 90%;
    }
    .LayoutContent{
        width: 100%;
        padding-top: 65px;
        margin-left: 0;
    }
}
@media (min-width: 1000px){
    .BottomNav{
        display: none;
    }
}
@media(min-width: 1600px){
    .DekstopNavCanvas{
        width:70%;
        margin-left:14%;
    }
}
@media(min-width: 2600px){
    .DekstopNavCanvas{
        width:40%;
        margin-left:30%;
    }
}