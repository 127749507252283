.OnLoadMain{
    width: 93%;
    height: auto;
    padding-top: 30px;
    margin-left: 3.5%;
}
.OnLoadProducts{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin-top: 30px;
}
@media (max-width: 700px){
    .OnLoadMain{
        width: 100%;
        height: auto;
        padding-top: 0;
        margin-left: 0;
    }
   .OnLoadCategory-home{
        width: 97%;
        height: 80px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        padding-left: 2.5%;
   }
   .OnLoadCategory-home::-webkit-scrollbar {
    display: none;
  }
   .OnLoadProducts{
       width: 95%;
       margin-left: 2.5%;
   }
   
}
@media (min-width: 700px){
    .OnLoadCategory-home{
        width: 100%;
        height: auto;
        display: flex;
       flex-flow: wrap;
       justify-content: space-between;
    }
} 

@media(min-width: 1600px){
    .OnLoadMain{
        width:70%;
        margin-left:14%;
    }

}
@media(min-width: 2400px){
    .OnLoadMain{
        width:40%;
        margin-left:30%;
    }
}