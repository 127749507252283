
 .HomeOnCat{
    width: 12%;
    height: 110px;
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
}
.onload-cat-home-logo{
    width: 70px;
    height: 70px;
    border-radius: 100%;
    margin-bottom: 5px;
    background:#ffffff;
    display: inline-block;
    animation: mymove 1s infinite;
}
.onload-cat-home{
    width:100%;
    height:30px;  
    background:#ffffff;
    border-radius:5px;
    animation: mymove 1s infinite;
}

@media (max-width: 700px){
    .HomeOnCat{
        width: 25%;
        height: 90px;
        display: inline-block;
        margin-right: 10px;
    }
    .onload-cat-home-logo{
        width: 50px;
        height: 50px;
    }
    .onload-cat-home{
        height:20px;  
    }
  }
 