.Orders{
    width: 100%;
    height: auto;
}

.OrdersMain{
    width: 100%;
    height: auto;
    /* margin-left: 10%; */
    padding-top: 5px;
}
.EmptyOrders{
    width: 100%;
    /* min-height: 91vh; */
    background:#F0F2F5;
    text-align: center;
    padding-top: 60px;
}
.EmptyOrders h2{
    padding: 18% 0 20px 0;
}
.EmptyOrders a{
    background-color: rgb(11, 145, 111);
    color: white;
    text-decoration: none;
    padding: 13px 60px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 5px;
}
.EmptyOrders a:hover{
    background-color: rgb(7, 114, 87);
}
.OrdersNav{
    width: 20%;
    height: 87vh;
    background-color: rgb(255, 255, 255);
    float: left;
    position: fixed;
    border-right: 1px solid #eee;
    box-shadow: 0 4px 5px 1px  rgb(204, 203, 203);
    text-align: center;
    padding-top: 30px;
}
.OrdersNav li{
    margin: 0 0 30px 0;
    list-style: none;
}
.OrdersNav a{
    text-decoration: none;
    padding: 10px 30px;
    border-radius: 25px;
    color: rgb(77, 74, 74);
}
.OrdersNav2{
    width: 95%;
    height: 40px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding-left: 2.5%;
}
.OrdersNav2::-webkit-scrollbar {
    display: none;
  }
.OrdersNav2 a{
    padding: 10px 20px;
    margin: 10px 10px 0 0;
    font-weight: bold;
    text-decoration: none;
    color: rgb(112, 108, 108);
}
.UserOrders{
    width: 80%;
    min-height: 88vh;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: space-between;
    float: right;
    padding-top: 20px;
    background: #F0F2F5;
}

@media (max-width: 1100px){
    .OrdersMain{
        width:100%;
        /* margin-top: 20px; */
        margin-left: 0;
    }
    .UserOrders{
        width: 100%;
        margin: 0 ;
        padding-top: 20px;
    }
    .OrdersNav{
        display: none;
    }
  }
 @media (min-width: 1100px){
    .OrdersNav2{
        display: none;
    }
 }
 
