.HomeOnProduct{
    width: 24%;
    height: 350px;
    background: #ffffff;
    border-radius: 5px;
    margin: 0 0 15px 0;
    animation: mymove 1s infinite;
}
@keyframes mymove {
    from {background-color:#e6e1e1}
    to {background-color:#c7caca}
  }
@media (max-width: 700px){
    .HomeOnProduct{
        width: 48%;
        height: 240px;
    }
  }
 @media (min-width: 1000px){
   
 } 