.Backdrop2{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 700;
    left: 0;
    top: 0;
    background: #99999be1;
}

