.UserCancelledOrders{
  width: 94%;
  margin-left: 2%; 
}
.NoOrders{
    width: 100%;
    text-align: center;
  }
  .NoOrders h3{
    padding: 15% 0 10px 0;
  }
  .NoOrders a{
    padding: 10px 40px;
     background: rgb(7, 131, 104);
     color: white;
     text-decoration: none;
     border-radius: 5px; 
     font-weight: bold;
  }
  .NoOrders a:hover{
    background: rgb(6, 109, 87);
  }
  @media (max-width: 1000px){
    .UserCancelledOrders{
      width: 95%;
    }
     .AllOrders{
         padding-bottom: 80px;
     }
     .NoOrders h3{
      padding: 55% 0 10px 0;
    }
    .baz-all-orders-header{
       font-size: 15px;
    }
    }
   @media (min-width: 700px){
      .AllOrders{
        width: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
      }
   }
   