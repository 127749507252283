.baz-browse-history{
  margin-left: 3.5%;
  padding: 30px 0;
}
@media(max-width: 1000px){
    .baz-browse-history{
        width: 97%;
        margin-left: 2.5%;
        padding-top: 0;
      }
      .baz-browse-history h3{
        font-size: 16px;
      }
}