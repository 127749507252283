.BazProduct3{
    width: 570px;
    height: 180px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 15px;
    border-radius: 5px;
}
.BazProduct3 a{
    color: black;
}
.BazProduct3 img{
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}
.baz-product3-others{
    width: 62%;
    height:100%;
    float: right;
}
@media(max-width: 1000px){
    .BazProduct3{
        width: 100%;
        height: 160px;
        border-radius: 2px;
    }
    .baz-product3-others h4{
        font-size: 15px;
        font-weight: 500;
        color:rgb(6, 109, 87);
    }
}