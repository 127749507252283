.baz-history-product{
    width: 570px;
    height: 180px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 15px;
    border-radius: 5px;
}
.baz-history-product a{
    color: black;
}
.baz-history-product img{
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}
.baz-history-product-others{
    width: 62%;
    height:100%;
    float: right;
}
@media(max-width: 1000px){
    .baz-history-product{
        width: 97%;
        height: 160px;
        border-radius: 2px;
      }
    .baz-history-product-others h4{
        font-size: 15px;
        font-weight: 500;
        color:rgb(6, 109, 87);
    }
}
