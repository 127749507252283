.baz-or-EmptyList{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: space-between;
}
/* style={{width:"49%", height:"200px", background:"white", borderRadius:"5px", marginBottom:"15px"}} */
.baz-or-EmptyList-order{
    width: 49%;
    height: 200px;
    background: rgb(255, 255, 255);
    border-radius: 5px;
    margin-bottom: 15px;
}
@media (max-width: 700px){
  .baz-or-EmptyList-order{
    width: 100%;
    height: 180px;
    border-radius: 2px;
  }
}