.baz-contact{
    width: 100%;
    height: 100vh;
    text-align: center;
}
.baz-contact-logo{
    width: 300px;
    height:50px;
    margin-top: 10%;
}

@media(max-width:1000px){
    .baz-contact{
        width: 100%;
        height: auto;
        text-align: center;
    }
    .baz-contact-logo{
        margin-top: 30%;
    }
    
}