.baz-user-profile{
    padding: 30px 0;
}
.UserProfile{
    width: 100%;
    height: auto;
    padding:0;
}
.ProfileHeader{
    width: 100%;
    height: 50px;
    background:rgb(255, 255, 255);
    position: fixed;
}
.DisplayNone{
    display: none;
}
.HeaderMain{
    width: 60%;
    height: 50vh;
    background: rgb(255, 255, 255);
    margin-left: 20%;
    box-shadow: 2px 2px 8px 0px #e2e1e1;
    border-radius: 5px;
}
.ProfileSwitch{
    width: 100%;
    height: 15%;
}
.ProfileSwitch ul{
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
}
.ProfileSwitch li{
    display: inline-block;
    margin: 10px 5px 0 0;
}
.ProfileSwitch a{
    padding: 10px 5px;
    text-decoration: none;
    color: black;
}
.ProfilePicture{
    width: 100%;
    height: 60%;
    text-align: center;
    padding: 2% 0;
    vertical-align: middle;
}
.Picture{
    width: 150px;
    height: 150px;
    background: rgb(6, 109, 87);
    border-radius: 100%;
    display: inline-block;
   
}
.Picture h1{
    color: white;
    font-size: 100px;
    text-transform: uppercase;
    padding: 3.5% 0;
    margin: 0;
}
.baz-user-InterestMain{
    width: 60%;
    height: auto;
    margin-left: 20%;
    margin-top: 20px;
}
.baz-user-InterestMain h3{
    float: left;
    margin: 0;
    padding: 10px 0 0 0;
}
.SeeAllLink{
    float: right;
    margin: 0;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    color: black;
    color:rgb(6, 109, 87);
}
.SeeAllLink:hover{
    background: rgb(201, 198, 198);
}

.UserFeedbacks{
    width: 60%;
    height: auto;
    margin-left: 20%;
    margin-top: 20px;
  
}
.baz-user-AllFeedBacks{
    width: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
}
.ProfileUpdateButton{
    float: right;
    padding: 12px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    outline: none;
}
.ProfileUpdateButton:hover{
    background: rgb(218, 221, 218);
}
.UpdateProfile{
    width: 100%;
    height: 100%;
}
.UpdateActionButton{
    float: right;
    padding: 10px 25px;
    margin: 10px 5px;
    cursor: pointer;
    border-radius: 25px;
    background: rgb(7, 131, 104);
    border: none;
    color: white;
    font-weight: bold;
    outline: none;
}
.UpdateActionButton:hover{
    background: rgb(6, 109, 87);
}
.CancelUpdateModal{
    float: left;
    padding: 10px 13px;
    border-radius: 100%;
    font-size: 15px;
    border: 1px solid #ccc;
    cursor: pointer;
    margin: 10px 5px;
    outline: none;
}
.CancelUpdateModal:hover{
    background: rgb(224, 222, 222);
}
.UpdateProfile h5{
    margin:0;
    padding:10px 0;
    font-size: 15px;
}
.UpdateProfile input{
    width: 95%;
    padding: 10px;
    border: none;
    border-bottom: 2px solid rgb(192, 192, 192);
    outline: none;
    font-size: 17px;
    background: rgb(247, 247, 247);
}
.ExploreLink{
    background: rgb(7, 131, 104);
    padding: 10px 35px;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
}
.ExploreLink:hover{
    background: rgb(6, 109, 87);
}
.baz-update-location-button{
    border: 1px solid rgba(182, 181, 181, 0.8);
    background: white;
    outline: none;
    border-radius: 5px;
}
.baz-update-location-button:hover{
    color: rgb(10, 107, 102);
}
@media (max-width: 1000px){
    .baz-user-profile{
        padding: 0 0 20px 0;
    }
    .UserProfile{
        padding:0;
    }
    .ProfileMain{
        width:100%;
        margin: 0;
    }
    .HeaderMain{
        width: 100%;
        height: 330px;
        margin-left: 0;
    }
    .ProfilePicture{
        padding: 2% 0;
    }
    .baz-user-InterestMain{
        width: 97%;
        height: auto;
        margin-left: 0%;
        margin-top: 20px;
        padding-left: 2.5%;
    }
    .baz-user-InterestMain h3{
        font-size: 15px;
    }
    .UserAllInterest{
        width: 100%;
        height: 210px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        padding-top: 10px;
        
    }
    .UserAllInterest::-webkit-scrollbar {
        display: none;
      }
    .UserFeedbacks{
        width: 95%;
        margin-left: 2.5%;
    }
    .UserFeedbacks h3{
        font-size: 15px;
    }
  }
 @media (min-width: 1000px){
    .UserAllInterest{
        width: 102.5%;
        height: 230px;
        padding-top: 10px;
        overflow: hidden;
        display: flex;
        flex-flow: wrap;
        flex-direction: row;
    }
 }
 