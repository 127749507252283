.ex-ProductCatOption1{
    width: 32%;
    background-color: rgb(255, 255, 255);
    margin: 0 1% 1% 0;
    cursor: pointer;
    border: 1px solid rgb(231, 229, 229);
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    color: black;
}
.ex-ProductCatOption1:hover{
    box-shadow: 2px 2px 8px 0px #9c9b9b;
}
.ex-ImageCont{
    width: 100%;
    height: 75%;
    overflow: hidden;
    position: relative;
    margin: 10px 0;
}
.ex-ImageCont img{
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.ex-ProductCatOption1 p{
    margin: 0;
    padding: 5px 0;
}
.ex-ProductCatOption1 h4{
    margin: 0;
    padding: 0;
}
@media (max-width: 1000px){
    .ex-ImageCont{
        width: 100%;
        height: 66%;
    }
    .ex-ProductCatOption1{
        width: 48%;
        height: 240px;
        border-radius: 5px;
        margin: 0 0 10px 0;
    }
    .ex-ProductCatOption1 h4{
        font-size: 15px;
        font-weight: 500;
        color:rgb(6, 109, 87);
    }
    
   }
  @media (min-width: 1000px){
    .ex-ProductCatOption1{
        width: 32%;
        height: 350px;
    }
  }
  