.Menu{
    width: 95%;
    height: 100%;
    margin: 0 0 0 10px;
}
.Menu ul{
    list-style: none;
    padding: 0;
    margin: 0 ;
}
.Menu li{
    margin: 9px 0;
    display: block;
}
.SubLink{
   padding: 5px 0;
   border-radius: 5px;
   cursor: pointer;
}
.SubLink a{
    display: block;
    text-decoration: none;
    color: black;
    font-size: 17px;
    padding: 10px  0 10px 0;
    width: 90%;
}
.SubLink button{
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    color: black;
    font-size: 17px;
}
.SubLink:hover{
    background: #F5F0F0;
    color: rgb(7, 121, 96);
}
.SubLink span, .SubLink2 span{
    margin: 0 13px;
    color: rgb(7, 121, 96);
}
.AuthLink{
    height: 70px;
    padding: 5px 0;
    margin: 5px 0;
    border-radius: 5px;
}
.baz-other-link{
    margin: 5px 6px;
    color: gray;
    text-decoration: none;
    font-size: 14px;
}
.baz-other-link:hover{
    text-decoration: underline;
}
.UserProf{
    width: 65px;
    height: 65px;
    background: rgb(7, 131, 104);
    border-radius: 100%;
    margin: 3px 0 0 5px;
    float: left;
    border-radius: 100%;
    display: flex;
   justify-content: center;
   align-items: center;
}
.UserProf h1{
    color: white;
    padding: 0;
     margin: 0;
}
.AuthLink:hover{
    background: #F5F0F0;
    
}
.SignLink{
    padding: 10px;
    color: black;
    font-size: 20px;
    text-decoration: none;
    border-radius: 5px;
}

@media (max-width: 1000px){
    .Menu ul{
        list-style: none;
        padding: 0;

    }
    .UserInf{
        width: 69%;
        height: 100%;
        float: right;
    }
  }
 @media (min-width: 1001px){
    .UserInf{
        width: 75%;
        height: 100%;
        float: right;
        margin-right: 2%;
    }
   
 }
 