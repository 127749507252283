.baz-other-skeleton{
    width: 93%;
    margin-left: 3.5%;
    padding-top: 40px;
}
.baz-other-skeleton-product{
    width: 570px;
    height: 180px;
    background: rgb(255, 255, 255);
    margin-bottom: 20px;
    border-radius: 2px;
}
@media(max-width:1000px){
    .baz-other-skeleton{
        width: 95%;
        margin-left: 2.5%;
        padding-top: 0;
    }
    .baz-other-skeleton-product{
        width: 100%;
        height: 160px;
        margin-bottom: 10px;
       
    }
}