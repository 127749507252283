.PrivacySettings{
    width: 100%;
    height: auto;
    padding-top: 50px;
}
.PrivacySettings h3{
    text-align: center;
}
.ChangePassword{
    width: 40%;
    height: 300px;
    background: white;
    margin-left: 30%;
}
.ChangePassword input{
    width: 80%;
   padding:13px 10px;
   border: 1px solid #eee;
   border-radius: 5px;
   margin: 10px 0;
}
.ChangePassword button{
    padding:12px 15px;
    background: rgb(10, 156, 108);
    border: none;
    color: white;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
}
.logout-from-other-device-button{
   margin: 0 0 0 5px;
   background: orangered;
}
@media (max-width: 1000px){
    .ChangePassword{
        width: 90%;
        height: 300px;
        background: white;
        margin-left: 5%;
        border-radius: 5px;
        text-align: center;
    }
  }
 @media (min-width: 1000px){
    .ChangePassword{
        width: 550px;
        height: 300px;
        background: white;
        margin-left: 30%;
        border-radius: 5px;
        text-align: center;
    }
 }
 