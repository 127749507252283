.single-product{
    width: 100%;
    height: auto;
    padding-top: 30px;
}
.baz-SaveButton{
    padding: 10px;
    margin: 0 10px 10px 0;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid rgb(138, 136, 136);
    outline: none;
    color: rgb(104, 101, 101);
}
.baz-SaveButton2{
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    color: rgb(16, 131, 112);
    border: 1px solid rgb(16, 131, 112);
}
.baz-AddCartButton, .baz-AddCartButton-not-stock{
    padding: 11px 0;
    width: 45%;
    margin: 0 10px 10px 0;
    font-size: 16px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    outline: none;
    background-color:rgb(7, 131, 104);
    color: white;
}
.baz-AddCartButton:hover{
    background-color: rgb(6, 109, 87);
}
.baz-AddCartButton-not-stock:hover{
    cursor:not-allowed;
}
.baz-AddCartButton-signLink{
    padding: 10px 45px;
    background-color:rgb(7, 131, 104);
    border-radius: 25px;
    text-decoration: none;
    color: white;
    margin: 5px 0;
    font-size: 16px;
}
.baz-WishButton-signLink{
    padding: 9px 20px;
    border: 1px solid rgb(134, 132, 132);
    margin-right: 10px;
    border-radius: 25px;
    text-decoration: none;
    color: rgb(88, 88, 88);
}
.baz-AddCartButton-signLink:hover{
    background-color: rgb(6, 109, 87);
}
.baz-ActiveImage{
    width: 70px;
    height: 70px;
    border: 1px solid rgb(8, 75, 66);
    border-radius: 5px;
    cursor: pointer;
    margin: 2px 5px 0 0;
}
.baz-NotActiveImage{
    width: 70px;
    height: 70px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid rgb(196, 192, 192);
    margin: 2px 5px 0 0;
    opacity: 0.7;
   
}
.baz-ActiveImage img, .baz-NotActiveImage img{
    max-height: 90%;
    max-width: 90%;
    margin-top: 5%;
}
.baz-NotActiveImage:hover{
    border: 1px solid rgb(15, 170, 149);
}
.baz-ExcellentButton{
    padding: 11px 20px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 25px;
    outline: none;
    color: rgb(16, 131, 112);
    border: 1px solid rgb(16, 131, 112);
}
.baz-ExcellentButton2{
    padding: 11px 20px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 25px;
    border: 1px solid rgb(138, 136, 136);
    outline: none;
    color: rgb(104, 101, 101);
}
.baz-Button1{
    padding: 12px 15px;
    margin: 0 10px 5px 0;
    background: white;
    border: 1px solid rgb(150, 149, 149);
    font-weight: bold;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
}
.baz-Button1:hover{
    border: 1px solid rgb(7, 127, 131);
    color: rgb(7, 127, 131);
}
.baz-Button2{
    padding: 12px 15px;
    margin: 0 10px 0 0;
    border: 1px solid rgb(7, 127, 131);
    font-weight: bold;
    cursor: pointer;
    outline: none;
    background: rgba(7, 127, 131, 0.342);
    border-radius: 5px;
}
.baz-Sizes{
    width: 100%;
    height: 80px; 
}
.baz-Sizes h5, .baz-SelectQuantity h5{
    margin: 0;
    padding: 10px 0 10px 0;
}
.baz-Sizes span:hover{
    color: rgb(14, 117, 95);
}
.baz-ProductMain{
    width: 93%;
    height: auto;
    margin-left:3.5%;
}
.baz-ProductOverview{
    width: 100%;
    height: 75vh;
}
.baz-ProductImagesMobile{
    width: 100%;
    height: 400px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.baz-ProductImagesMobile::-webkit-scrollbar {
    display: none;
  }
.baz-mobile-image-container{
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
}
.baz-ProductImagesMobile img{
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}
.baz-ProductImages{
    width: 58%;
    height: 100%;
    float: left;
    /* background: red; */
}
.baz-ImagesList{
    width: 80px;
    height: 100%;
    float: left;
    /* position: absolute; */
}

.baz-ImageCanvas{
    width: 85%;
    height: 100%;
    float: right;
    border-radius: 5px;
    background: white;
    position: relative;
}
.baz-ImageCanvas img{
    width: auto;
    height: auto;
    max-width: 100%;
    height: 96%; 
    cursor: zoom-in;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.baz-ProductDetails{
    width: 37%;
    height: 100%;
    float: right;
}
.baz-SelectQuantity{
    width: 100%;
    height: auto;
    margin: 0px 0 30px 0;
}
.baz-Buttons{
    width: 100%;
    height: 60px;
}
.baz-Buttons span{
    margin: 0 5px;
}
.baz-ProductOthers{
    width: 65%;
    height: auto;
    margin: 30px 0 0 0;
}
.baz-CreateFeedback{
    width: 100%;
    height: 50px;
}
.baz-CreateFeedback button{
    width: 91%;
    background-color: rgb(255, 255, 255);
    float: right;
    height: 50px;
    cursor: pointer;
    border-radius: 25px;
    text-align: left;
    border: 1px solid rgb(228, 225, 225);
    padding:  0 20px;
    color: gray;
    outline: none;
}
.baz-FeedUser{
    width: 50px;
    height: 50px;
    background-color: rgb(19, 121, 139);
    border-radius: 100%;
    float: left;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.baz-AllFeedbacks{
    width: 100%;
    height: auto;
    margin-top: 20px;
}
.product-view-cat-link{
    color: black;
    font-size: 17px;
    padding: 0 0 0.2px 0;
    text-decoration: none;
    border-bottom: 0.6px solid rgb(133, 130, 130);
}
.product-view-cat-link:hover{
    border-bottom: 0.6px solid rgb(15, 117, 87);
    color: rgb(15, 117, 87);
}
.ReviewForm{
    width: 95.8%;
    height: 100%;
    margin-left: 2%;
}
.ReviewMain{
   width: 100%;
   height: 200px;
   margin-top: 20px;
}
.feedBack-button-signLink{
    width: 84%;
    background-color: rgb(255, 255, 255);
    float: right;
    cursor: pointer;
    border-radius: 25px;
    text-align: left;
    border: 1px solid rgb(228, 225, 225);
    padding:  15px 20px;
    color: gray;
    outline: none;
    text-decoration: none;
}
.baz-CancelReviewModal,.baz-CancelReviewUpdateModal{
    float: right;
    margin: 5px 0 0 10px;
    padding: 12px 15px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 100%;
}
.baz-CancelReviewModal:hover,.baz-CancelReviewUpdateModal:hover{
    background: rgba(10, 139, 107, 0.26);
    color: rgb(10, 139, 107);
}
.FeedBackButton{
    background: rgb(7, 131, 104);
    color: white;
    font-weight: bold;
    margin: 5px 0 0 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    float: right;
    outline: none;
    cursor: pointer;
}

.FeedBackButton:hover{
    background:rgb(6, 109, 87);
}
.UserBox{
    width: 100px;
    height: 100px;
    border-radius: 100%;
}
.WriteReview{
    width: 85%;
    height: 100%;
    float: right;
}
.ReviewForm textarea{
    width: 96%;
    height: 45%;
    border-radius: 5px;
    border: 1px solid #eee;
    padding: 2%;
    resize: none;
    outline: none;
    font-size: 15px;
    font: inherit;
}
.RevUser{
    width: 60%;
    height:80%;
    float: left;
    padding-top:"10px"
  }
  
  .baz-imageview-canvas-header{
      width: 100%;
      height:8%;
  }
  .baz-imageview-canvas{
      width: 100%;
      height:80%;
  }
  .baz-imageview-all-images{
    width:100%;
    height: 12%;
    text-align: center;
  }
  .baz-imageview-canvas-main{
    width: 100%;
    height: 100%;
    position: relative;
  }
  
 
  .image-canvas-closer{
      float: right;
      padding: 11px 15px;
      border-radius: 100%;
      font-size: 15px;
      margin:10px;
      cursor: pointer;
      border: none;
      outline: none;
  }
  .image-canvas-closer:hover{
      background: rgba(16, 131, 112, 0.322);
      color: rgb(16, 131, 112);
  }

   .baz-updatefeedback-button{
       float: right;
       padding: 11px 20px;
       border: none;
       background: rgb(7, 131, 104);
       border-radius: 5px;
       color: white;
       outline: none;
       cursor: pointer;
       outline: none;
   }
   .baz-updatefeedback-button:hover{
    background: rgb(6, 109, 87);
   }
 @media (max-width: 1000px){
    .baz-ActiveImage{
        width: 50px;
        height: 50px;
    }
    .baz-NotActiveImage{
        width: 50px;
        height: 50px;
    }
    .baz-imageview-canvas-main img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%; 
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    .single-product{
        width: 100%;
        height: auto;
        padding-top: 0;
        margin-left: 0;
    }
    .baz-ProductMain{
        width: 100%;
        height: auto;
        margin-left: 0;
    }
    .baz-ProductOverview{
        width: 100%;
        height: auto;
    }
    .baz-ProductImages{
        display: none;
    }
    .baz-ProductDetails{
        width: 95%;
        height:auto;
    }
    .baz-ProductOthers{
        width: 90%;
        margin: 30px 5%; 
    }
   
    .baz-ProductOthers h3{
        font-size: 15px;
    }
    .baz-CreateFeedback button{
        width: 82%;
        border-radius: 5px;
    }
    .feedBack-button-signLink{
        width: 70%;
        border-radius: 5px;
    }
    .baz-RelatedProducts{
        width: 97%;
        height: auto;
        margin: 0 0 20px 0;
        padding-left: 2.5%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
     }
     .baz-RelatedProducts::-webkit-scrollbar {
        display: none;
      }
     .RevUser{
        width: 80%;
    }
    .baz-imageview-all-images{
        width:100%;
        height: 10%;
    }
    .baz-imageview-canvas{
        width: 100%;
        height:80%;
        float: right;
    }
    .relatedview-headline{
        margin-left: 2.5%;
        font-size: 15px;
    }
    .baz-imageview-canvas{
        width: 100%;
        height:75%;
    }
    
  }
 @media (min-width: 1000px){
    .baz-imageview-canvas-main img{
        width: auto;
        height: auto;
        max-width: 100%;
        height: 100%; 
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    .baz-ProductImagesMobile{
        display: none;
    }
    .baz-RelatedProducts{
        width: 93.5%;
        height: auto;
        margin: 20px 0 20px 3.5%;
        display: flex;
        flex-flow: wrap;
     
     }
     .relatedview-headline{
         margin-left: 3.5%;
     }
 } 

 @media(min-width: 1600px){
    .relatedview-headline{
        margin-left: 14%;
    }
    .baz-RelatedProducts{
        width:70%;
        margin-left:14%;
    }
    .baz-ProductMain{
        width:70%;
        margin-left:14%;
    }
}
@media(min-width: 2400px){
    .relatedview-headline{
        margin-left: 30%;
    }
    .baz-RelatedProducts{
        width:40%;
        margin-left:30%;
    }
    .baz-ProductMain{
        width:40%;
        margin-left:30%;
    }
}