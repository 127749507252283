.Backdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 700;
    left: 0;
    top: 0;
}
@media (max-width:1100px){
    .Backdrop{
        background: #6262635e;
    }
}

