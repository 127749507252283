.baz-about{
    width: 100%;
    padding: 40px 0 0 0;
    background-color: white;
}
.baz-about-header{
    text-align: center;
}
.baz-about-header img{
    width: 20%;
}
.baz-about-header p{
    padding: 0 25%;
    font-size: 50px;
    font-weight: 300;
    margin: 0 0 30px 0;
}
.bazban-featured-talk{
    width: 93%;
    height: auto;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin:40px 0  60px 3.5%;
}
.bazban-featured-talk-section{
    width:32%;
    height: 400px;
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 2px 8px 0px #888888;
    /* padding: 2%; */
}
.bazban-featured-talk-section h1{
    font-size: 25px;
}
.bazban-featured-talk-section p{
    margin: 0;
    padding: 5px 15px;
}
.bazban-featured-talk-section h1{
    color: rgb(39, 160, 144);
}
.baz-about ul{
    width: 50%;
    margin-left: 22%;
    text-align: center;
   
}

.baz-about li{
    background: rgb(255, 255, 255);
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
}
.baz-about li p{
    padding: 0 30px;
    text-align: left;
}
.baz-about-pic-left{
    width: 49%;
    height: 100%;
    float: left;
}
.baz-about-pic-right{
    width: 49%;
    height: 100%;
    float: right;
}
.baz-about-details{
    width: 80%;
    margin-left: 10%;
    font-size: 18px;
}
@media(max-width: 1000px){
    .baz-about{
        padding: 0 0;
    }
    .baz-about ul{
      width:100%;
      margin-left: 0%;
      padding: 0;
   }
.baz-about-header p{
    padding: 0 5%;
    font-size: 25px;
    margin: 0;
 }
.baz-about-header img{
    width: 50%;
 }
 .baz-about-details{
    width: 95%;
    margin-left: 2.5%;
    font-size: 15px;
    color: #1C1E2F;
}
.bazban-featured-talk-section{
    width: 100%;
    margin-bottom: 20px;
}
}