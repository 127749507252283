.CartProduct{
    width: 95%;
    height: 130px;
    background: rgb(255, 255, 255);
    margin: 0 0 15px 2.3% ;
    cursor: pointer;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 5px 0;
}
.CartProduct:hover{
    box-shadow: 1px 1px 5px 0px rgb(228, 227, 227);
}
.CartProduct a{
    color: black;
}
.CproductDetails{
   width: 80%;
   height: 100%;
   float: left;
}
.CproductDetails img{
    max-width: 100%;
    max-height: 100%;
}
.CDetails{
    width: 60%;
    height: 100%;
    float: right;
}
.baz-cartitem-remove{
    float: right;
    margin: 10px;
    font-size: 17px;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    color: rgb(105, 104, 104);
}
.baz-cartitem-remove:hover{
    color: rgb(43, 42, 42);
}
.baz-quantity-button1{
     padding:7px 10px;
     cursor: pointer;
     border: 1px solid rgb(204, 203, 203);
     background: white;
     border-radius: 5px;
     outline: none;
}
.baz-quantity-button2{
    padding:7px 10px;
    border: 1px solid rgb(204, 203, 203);
    background: white;
    border-radius: 5px;
    outline: none;
    cursor: not-allowed;
    
}
.baz-quantity-button1:hover{
    border: 1px solid rgb(26, 145, 109);
}