.OrderView{
    width: 100%;
    height: 100%;
    
}
.OrderHeader{
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #eee;
}
.baz-or-OrderCancelButton1{
    float: right;
    padding: 10px 13px;
    margin: 10px;
    border-radius: 100%;
    cursor: pointer;
    border: 1px solid #eee;
    font-size: 16px;
    outline: none;
}
.baz-or-OrderCancelButton1:hover{
    background: rgb(212, 209, 209);
}
.OrderDet{
    width: 100%;
    height: 87%;
    overflow-y: scroll;
}

.baz-or-OrderCancelButton{
    padding: 10px 30px;
    margin: 20px;
    cursor: pointer;
    border: 1px solid #eee;
    color: white;
    background: red;
    border-radius: 5px;
    outline: none;
}
.baz-or-OrderCancelButton:hover{
    background: rgb(173, 6, 6);
}
