.baz-control-order-nav{
    width: 100%;
    height: 50px;
}
.baz-control-order-nav a{
    padding: 10px;
    margin: 5px;
   text-decoration: none;
   color: black;
}
.baz-control-order-nav input{
    padding: 10px;
    margin: 0 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    outline: none;
}