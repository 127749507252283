.AdminPendingOrders{
    width: 100%;
    height: auto;
}
.AdminPendingOrders h4{
    margin: 0;
    padding: 0 0 10px 0;
}
.baz-ad-AllOrders{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
}
