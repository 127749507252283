.Backdrop20{
    width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7000;
  left: 0;
  top: 0;
  background: #6262635e;
}
.Loader{
    border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #059C88;
  border-right: 5px solid #059C88;
  border-bottom: 5px solid #059C88;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
 
}
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.Spinner{
    width: 10%;
height: 10%;
position: fixed;
z-index: 70000;
box-sizing: border-box;
padding: 10px;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
top: 35%;
left: calc(45%);
}