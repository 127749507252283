.baz-productview-2{
    background: rgb(255, 255, 255);
    border: 1px solid rgb(231, 229, 229);
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    overflow: hidden;
}
.baz-productview-2:hover{
    box-shadow: 2px 2px 8px 0px #888888;
}
.baz-productview-2-imagecontainer{
    width: 100%;
    height: 72%;
    text-align: center;
    /* position: relative; */
    padding:10px 0;
}
.baz-productview-2 img{
    max-width: 100%;
    max-height: 100%;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute; */
}
.baz-productview-2 p{
    margin: 0;
    padding: 4% 0 5px 0;
}

@media (max-width: 500px){
    .baz-productview-2:hover{
        box-shadow: none;
    }
    .baz-productview-2{
        width:85%;
        height: 345px;
        margin-right: 10px;
        display: inline-block;
        border-radius: 5px;
    }
    .baz-productview-2-imagecontainer{
        width: 100%;
        height: 75%;
        text-align: center;
    }
    .baz-productview-2 p{
        margin: 0;
        padding: 0;
    }
    .baz-productview-2 p{
        margin: 0;
        padding: 4% 0 5px 0;
    }
    .baz-product2-price{
        font-size: 15px;
        font-weight: 500;
        color:rgb(6, 109, 87);
    }
  }
  @media (min-width: 501px) and (max-width: 999px){
    .baz-productview-2{
        width:60%;
        height:410px;
        margin-right: 10px;
        display: inline-block;
        border-radius: 2px;
    }
  }
 @media (min-width: 1000px){
    .baz-productview-2{
        width:24%;
        height:350px;
    }
 }
 