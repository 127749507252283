.baz-ad-PendingOrder{
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    border-radius: 5px;
}

.baz-ad-OrderHeader{
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ccc;

}
.baz-ad-OrderDetails{
    width: 100%;
    height: 75%;
    overflow-y: scroll;
}
.baz-ad-OrderActions{
    width: 96%;
    height: 60px;
    margin-top: 2%;
}
.baz-ad-OrderActions button{
    padding: 10px 30px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    float: right;
    font-size: 16px;
}
.baz-ad-CancelButton{
   background: none;
   border: 1px solid rgb(202, 200, 200);
}
.baz-ad-AcceptButton{
    background: rgb(5, 104, 107);
    color: white;
    border: 1px solid rgb(5, 104, 107);
}
.baz-ad-AcceptButton:hover{
    background: rgb(4, 91, 94);
    color: white;
    border: 1px solid rgb(4, 87, 90);
}