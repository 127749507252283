.CartModal {
    position: fixed;
    z-index: 7000;
    box-sizing: border-box;
    top: 0px;
    height: 83vh;
    width: 100%;
    height: 100vh;
    background: rgb(255, 255, 255);
}

@media (min-width: 1000px){
    .CartModal{
        width:400px;
        height: 100vh;
        /* top: 60px; */
        right: 0%;
        box-shadow: 2px 2px 8px 0px #888888;
        /* border-radius: 5px; */
        z-index: 7000;
    }
}