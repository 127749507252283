.App {
  min-height: 100vh;
  background: #F0F2F5;
}
@media (max-width: 1000px){
    .App{
      padding-bottom: 50px;
      min-height: 90vh;
    }
  }
 @media (min-width: 1000px){
   
 }
