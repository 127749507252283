.Notifications{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    
}
.NotiHeader{
    width: 100%;
    height: 50px;
}
.allNotifications{
    width: 100%;
    height: 90%;
    overflow-y: scroll;
    position: absolute;
    left: 0;
    overflow-x: hidden;
}
.allNotifications::-webkit-scrollbar {
    display: none;
  }
  .baz-empty-notification-img{
      width: 50%;
      height: 50%;
  }
@media (max-width: 700px){
    .NotiHeader{
        border-bottom: 1px solid #eee;
    }
    .NotiHeader button{
        float: left;
        margin: 13px 0 0 13px;
        background: none;
        border: none;
        font-size: 20px;
    }
    .NotiHeader h3{
        margin: 0;
        padding: 12px 35% 0 0;
        float: right;
    }
  }
 @media (min-width: 700px){
     .NotiHeader button{
         display: none;
     }
    .NotiHeader h3{
        margin: 0;
        padding: 10px;
    }
 }
 