.AddProduct-main{
    width: 50%;
    height: 75vh;
    margin-left: 25%;
    overflow-y: scroll;
}
.preview-images{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    padding: 20px 0;
    text-align: center;
}
.preview-image{
    width: 140px;
    height: 150px;
    border: 1px solid gray;
}
.preview-image img{
    width: 100%;
    height: 75%;
}
.preview-image button{
    cursor: pointer;
}
.editor-area{
    background: yellow;
    height: 200px;
    overflow-y: scroll;
}