.OnCatDetails{
    width: 100%;
    margin-top: 30px;
}
.baz-catesection-skeleton-header{
    width: 250px;
    height: 30px;
    margin: 0 0 20px 0;
    background:#ffffff;
    border-radius: 5px;
    animation: mymove 1s infinite;
}
.baz-category-skeleton-main-header{
  width: 250px;
  height: 30px;
  background: white;
  border-radius: 5px;
  animation: mymove 1s infinite;
}
@media (max-width: 700px){
  .baz-catesection-skeleton-header{
     margin: 0 0 20px 2.5%;
     height: 30px;
  }
  .baz-category-skeleton-main-header{
    height: 30px;
  }
    .baz-catesection-skeleton{
      width: 100%;
    }
   .OnLoadCategory{
        width: 97%;
        height: 110px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        padding-left: 2.5%;
   }
  .OnCatDetails{
    width: 97%;
    margin-left: 2.5%;
}
   
}
@media (min-width: 700px){
    .OnLoadCategory{
        width: 93%;
        margin-left: 3.5%;
        height: auto;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
    }
    .baz-catesection-skeleton{
        width: 93%;
        margin-left: 3.5%;
      }
} 