.baz-control-panel-check{
    text-align: center;
}
.baz-control-panel-check input{
     width: 70%;
     padding: 15px 10px;
     display: inline-block;
     margin: 40% 0  10px 0;
     border: 1px solid rgb(211, 207, 207);
     border-radius: 5px;
     outline: none;
}
.baz-control-panel-check button{
    padding: 13px 60px;
    border: none;
    background: rgb(12, 133, 107);
    color: white;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
}
.baz-control-panel-check button:hover{
    background: rgb(9, 102, 82);
}
.baz-ad-nav{
    width: 100%;
    height: 40px;
    background: rgb(214, 213, 213);
    position: fixed;
    padding-top: 10px;
}
.baz-ad-nav a {
    padding: 10px;
    margin: 5px;
    text-decoration: none;
    color: black;
}
.baz-ad-nav button{
    float: right;
    margin: 5px 10px;
    padding: 5px 10px;
    cursor: pointer;
}