.ex-Cat{
    width: 45%;
    height: 150px;
    margin: 10px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    color: black;
}
.ex-Cat:hover{
    /* background: #F5F0F0; */
    color: rgb(39, 143, 103);
}
.ex-Cat img{
    width: 50px;
    height: 50px;
    padding-top: 35px;
}
.ex-Cat p{
    margin: 0;
    padding: 10px 0;
    font-size: 14px;
}
@media (max-width: 1000px){
    .ex-Cat{
        width: 42%;

    }
  }
  