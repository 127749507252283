.ex-Categories{
    width: 100%;
   height: auto;
}
.ex-CatProducts{
    width: 80%;
    height: auto;
    background: #F0F2F5;
    float: right;
    padding: 30px 0 10px 0;
    
}
.AllCatPro{
    width: 94%;
    height: auto;
    margin-left: 3%;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
}
.AllCatPro-skeleton{
    width: 92.5%;
    height: auto;
    margin-left: 3%;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.ex-CategoryList{
    width: 20%;
    height: 91vh;
    background:rgb(255, 255, 255);
    position: fixed;
    border-right: 1px solid #cccc;
    box-shadow: 0 4px 5px 1px  rgb(204, 203, 203);
    float: left;
    padding-top: 10px;
}
.ex-CategoryList2{
    width: 20%;
    height: 91vh;
    background:rgb(255, 255, 255);
    position: fixed;
    border-right: 1px solid #cccc;
    box-shadow: 0 4px 5px 1px  rgb(204, 203, 203);
    float: left;
    padding-top: 32px;
}
.ex-CategoryList ul{
    list-style: none;
    height: 71%;
    overflow-y: scroll;
    padding: 0;
    left: 0;
    overflow-x: hidden;
}
.ex-CategoryList ul::-webkit-scrollbar {
    display: none;
}
.ex-CategoryList1{
    width: 85%;
    margin: 10px 0;
    background: #0b968a1f;
   padding: 10px 15px;
   margin-left: 2%;
   cursor: pointer;
   border-radius: 5px;
}
.ex-CategoryList20{
    width: 85%;
    margin: 10px 0;
    background: #ffffff;
   padding: 10px 15px;
   margin-left: 2%;
   cursor: pointer;
   border-radius: 5px;
}
.CategoryListM1{
    margin: 10px 0;
    background: #0a9488;
   padding: 10px 25px;
   margin-left: 2%;
   cursor: pointer;
   border-radius: 25px;
   color: white;
}
.CategoryListM20{
    margin: 10px 0;
    background: #ffffff;
    padding: 10px 25px;
    margin-left: 2%;
    cursor: pointer;
    border-radius: 25px;
}
.ex-CategoryList20:hover{
    background: #e7e4e4;
}
.ex-CatMain{
    width: 100%;
    padding: 8px 0;
    background: #F5F0F0;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 10px;
}
.ex-CatMain:hover{
    background: #e7e4e4;
}
.CatActive{
    width: 100%;
    height: 27px;
    background-color: #F0F2F5;
    margin:0px  0px 20px 0;
    padding: 10px 0;
    cursor: pointer;
}
.PriceFilter{
    width: 80%;
    padding:10px;
    margin: 5px 0 5px 9%;
    cursor: pointer;
    border: 1px solid #eee;
    background: white;
    border-radius: 5px;
    outline: none;
}
.PriceFilter1{
    width: 80%;
    padding:10px;
    margin: 5px 0 5px 9%;
    cursor: pointer;
    border: 1px solid rgb(17, 128, 94);
    background: white;
    border-radius: 5px;
    color: rgb(17, 128, 94);
    outline: none;
}
.PriceFilter:hover{
    color: rgb(17, 128, 94);
}

.ex-AllCat{
    width: 94%;
    height: 93%;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    margin: 15px;
    overflow-y: scroll;
    position: absolute;
    left: 0;
    overflow-x: hidden;
}
.ex-AllCat::-webkit-scrollbar {
    display: none;
}
.baz-mobile-subcat-skeleton{
    width: 95%;
    height: auto;
    margin:0 0 0 2.5%;
}
.baz-explorecategory-empty{
    width: 100%;
    text-align: center;
    padding: 15% 0;
}
.baz-explorecategory-empty h2{
    margin: 0;
    padding: 0;
}
.baz-explorecategory-empty p{
    padding: 0 35%;
    margin: 5px 0 25px 0;
}
.baz-explorecategory-empty a{
    padding: 10px 30px;
    background: rgb(7, 131, 104);
    color: white;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
}
.baz-explorecategory-empty a:hover{
    background: rgb(6, 109, 87);
}
@media (max-width: 1100px){
   .ex-CategoryList{
       display: none;
   }
   .ex-CategoryList2{
       display: none;
   }
   .ex-CatProducts{
       width: 100%;
       float: left;
       margin-left: 0;
       margin-right: 0;
       padding-top: 10px;
       padding-bottom: 60px;
       background: #F0F2F5;
   }
   .AllCatPro{
    width: 95%;
    height: auto;
    margin-left: 2.5%;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: space-between;
}
   .ex-CatMain{
       background: white;
       margin-left: 2.5%;
       width: 95%;
       padding: 3px;
       border-radius: 5px;
   }
  .ex-MobileCats{
      width: 100%;
      height: auto;
      background: none;
  }
  .MobileSubCats{
      width: 97.4%;
      height: 60px;
      padding-left: 2%;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
  }
  .MobileSubCats::-webkit-scrollbar {
    display: none;
  }
  .MobileSubCats li{
      display: inline-block;
  }
  .baz-explorecategory-empty p{
    padding: 0 15%;
    margin: 5px 0 25px 0;
}
  }
 @media (min-width: 1100px){
   .Category{
       width: 15%;
       height: 100%;
   }
   .ex-MobileCats{
       display: none;
   }
   .baz-mobile-subcat-skeleton{
      display: none;
   }
 }
 