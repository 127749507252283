.ReviewUpdateModal {
    position: fixed;
    z-index: 700;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
  
}

@media (max-width: 600px){
    .ReviewUpdateModal {
        width: 100%;
        height: 290px;
        left: calc(0);
        width: 100%;
        bottom: 0;
        border-radius: 0px;
    }
}
/* Mobile Only */
@media (min-width: 600px){
    .ReviewUpdateModal{
        margin-top: 80px;
        width: 50%;
        height: 300px;
        left: calc(24%);
        border-radius: 5px;
        /* box-shadow: 2px 2px 8px 0px #888888; */
    }
}
