.SuperbProducts{
    width: 100%;
    height: auto;
    background: #F0F2F5;
    padding-bottom: 20px;
}
.EmptySaved{
    padding-top: 50px;
    text-align: center;
}
.EmptySaved h3{
    padding: 13% 0 10px 0;
}
.EmptySaved a{
    background:rgb(7, 131, 104);
    padding: 10px 50px;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    color: white;
}
.EmptySaved a:hover{
    background:rgb(6, 109, 87);
}
@media (max-width: 1000px){
    .AllSuperb{
        width: 93%;
        height: auto;
        padding: 0;
        margin-left: 2.5%;
    }
    .EmptySaved h3{
        padding: 40% 0 10px 0;
    }
    .AllSuperb h3{
        margin: 0;
        padding: 0 0 10px 0;
        font-size: 16px;
    }
}
/* Mobile Only */
@media (min-width: 1000px){
    .AllSuperb{
        width: 50%;
        height: auto;
        padding-top: 10px;
        margin-left: 5%;
    }

}
