.UpdateProfileModal {
    position: fixed;
    z-index: 700;
    box-sizing: border-box;
    top: 0px;
    background: rgb(255, 255, 255);
}

@media (max-width: 800px){
    .UpdateProfileModal {
        width: 100%;
        height: 100vh;
        left: calc(0);
        width: 100%;
    }
}
/* Mobile Only */
@media (min-width: 801px){
    .UpdateProfileModal{
        width: 45%;
        height: 80vh;
        left: calc(27%);
        top: 60px;
        /* box-shadow: 2px 2px 8px 0px #888888; */
        border-radius: 5px;
    }
}
