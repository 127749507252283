.OnLoadInterestsProducts{
    width: 93%;
    margin-left: 3.5%;
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

}
.on-load-interest-header{
    width: 250px;
    height:40px;
    background: white;
    margin: 0 0 20px 3.5%;
    border-radius: 5px;
    animation: mymove 1s infinite;
}
@media (max-width:1000px){
    .OnLoadInterests{
        width: 97.5%;
        height: auto;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        padding-left: 2.5%;
    }
    .OnLoadInterests::-webkit-scrollbar {
        display: none;
      }
    .on-load-interest-header{
        height:30px;
        margin: 0 0 20px 2.5%;
     
    }
    .OnLoadIn{
        width: 45%;
        height: 180px;
        margin-right: 10px;
        display: inline-block;
        background: rgb(255, 255, 255);
        border-radius: 5px;
        animation: mymove 1s infinite;
    }
    .OnLoadInProduct{
        width: 48%;
        height: 240px;
        background: rgb(255, 255, 255);
        border-radius: 5px;
        margin: 0 0 15px 0;
        margin-bottom: 10px;
        animation: mymove 1s infinite;
    }
    .OnLoadInterestsProducts{
        margin-left: 2.5%;
        margin-top: 20px;
        border-radius: 5px;
    }
}
@media (min-width: 1001px){
    .OnLoadInterests{
        width: 93%;
        margin-left: 3.5%;
        display: flex;
        flex-flow: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
    
    }
    .OnLoadIn{
        width: 18%;
        height: 200px;
        background: #ffffff;
        border-radius: 5px;
        animation: mymove 1s infinite;
    }
    .OnLoadInProduct{
        width: 24%;
        height: 350px;
        background: #ffffff;
        border-radius: 5px;
        margin: 0 0 15px 0;
        animation: mymove 1s infinite;
    }
}