.baz-product-skeleton-web{
    width: 100%;
    height: 100%;
}
.OnLoadViewLeft{
    width: 58%;
    height: 100%;
    float: left;
}
.OnLoadViewLeftImages{
    width:12%;
    height: 100%;
    float: left;
}
.OnLoadViewLeftImage{
    width: 83%;
    height: 100%;
    background:#ffffff;
    float: right;
    border-radius: 5px;
    animation: mymove 1s infinite;
}
.OnLoadViewRight{
    width: 38%;
    height: 100%;
    float: right;
}

 @media (max-width: 1000px){
    .baz-product-skeleton-web{
        display: none;
    }
  }
 @media (min-width: 1000px){
   .baz-product-skeleton-mobile{
       display: none;
   }
 } 