.prof-BazSubCategory{
    height: 150px;
    background: rgb(255, 255, 255);
    cursor: pointer;
    text-align: center;
    margin:0 10px 15px 0;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 8px;
    overflow: hidden;
}
.prof-BazSubCategory a{
    text-decoration: none;
    color: black;
}

.prof-BazSubCategory p{
    color: white;
    bottom: 0;
    left:0;
    margin: 0;
    padding: 10px;
    position: absolute;
    font-weight: 500;
    font-size: 20px;
}

.NotInterestedButton1{
    width: 90%;
    padding: 10px 0;
    border: 1px solid #ccc;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    font-size: 13px;
    outline: none;
    background: none;
}
.NotInterestedButton1:hover{
    color: rgb(14, 138, 107);
    border: 1px solid rgb(235, 232, 232);
    background: rgb(240, 237, 237);
}
.InterestedButton{
    width: 90%;
    padding: 10px 0;
    border: 1px solid rgb(6, 131, 114);
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    font-size: 13px;
    outline: none;
    background: rgb(6, 131, 114);
    color: white;
}

@media (max-width: 1000px){
    .prof-BazSubCategory{
        width:45%;
        height: 200px;
        display: inline-block;
        margin-right: 10px;
        border-radius: 5px;
    }
    .prof-BazSubCategory p{
        font-size: 15px;
    }
  }
 @media (min-width: 1000px){
    .prof-BazSubCategory{
        width:31.5%;
        height: 220px;
    }
 }
 