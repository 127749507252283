.baz-not-found{
    width: 100%;
    text-align: center;
    padding-top: 15%;
}
.baz-not-found h2{
    margin: 0;
    padding: 0;
}
.baz-not-found p{
    padding: 0 35%;
    margin: 5px 0 25px 0;
}
.baz-not-found a{
    padding: 10px 30px;
    background: rgb(7, 131, 104);
    color: white;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
}
.baz-not-found a:hover{
    background: rgb(6, 109, 87);
}
@media (max-width:1000px){
    .baz-not-found{
        padding-top: 35%;
    }
    .baz-not-found p{
        padding: 0 10%;
        margin: 5px 0 25px 0;
    }
}