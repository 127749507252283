.baz-collection1{
    width: 93%;
    height: auto;
    margin: 10px 0 10px 3.5%;
}
.baz-collection1 h3{
    float: left;
    margin: 0;
    padding: 5px 0;
    font-size: 22px;
}
/* style={{padding:"8px 0", margin:"0",float:"right", width:"81%"} */
.baz-collection1-category-headername{
    color: rgb(8, 8, 8);
    font-size: 22px;
    padding: 3px 0;
    margin: 0;
    float: right;
    width: 81%;
}
.collectionview1-seeall-link1{
    float: right;
    padding: 10px 20px;
    text-decoration: none;
    color: rgb(21, 122, 129);
    font-size: 18px;
    border-radius: 5px;
    font-weight: 500;
}
.collectionview1-seeall-link1:hover{
    background: rgb(207, 209, 207);
}
.collectionview1-products-all{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
   
}
.baz-collection1-header{
    width: 100%;
    height: 50px;
    margin-bottom: 5px;
}
.baz-collection1-header-see-all{
   width: 20%;
   height: 100%;
   float: right;
}
.baz-collection1-header-mobile{
    width: 99%;
    height: 40px;
    margin-bottom: 13px;
}
@media (max-width: 1000px){
    .baz-collection1-header{
        display: none;
    }
    .baz-collection1{
        width: 95%;
        height: auto;
        margin: 10px 2.5%;
    }
    .baz-collection1 h3{
        font-size: 15px;
    }
    .collectionview1-seeall-link1{
       display: none;
    }
    .collectionview1-seeall-link2{
        float: right;
        font-size: 18px;
        color: black;
    }
  }
 @media (min-width: 1001px){
    .collectionview1-seeall-link2{
        display: none;
    }
    .baz-collection1-header-mobile{
        display: none;
    }
 }

 @media(min-width: 1600px){
    .baz-collection1{
        width:70%;
        margin-left:14%;
    }
   
}
@media(min-width: 2400px){
    .baz-collection1{
        width:40%;
        margin-left:30%;
    }
  
}
 