.OnLoadProfile{
    width: 60%;
    margin-left: 20%;
    text-align: center;
    vertical-align: middle;
}
.on-load-profile-pic{
    border-radius: 100%;
    height: 150px;
    width: 150px;
    margin-top: 6%;
    display: inline-block;
    background: #dad8d8;
}

 @media (max-width: 600px){
   .OnLoadProfile{
       width: 100%;
       margin-left: 0;
   }
  }
