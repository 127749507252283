.baz-OrderedProduct{
    width: 70%;
    height: 150px;
    margin: 10px 2.5%;
    background: rgb(236, 236, 235);
    cursor: pointer;
}

.baz-OrderedProduct img{
    width: 35%;
    height: 100%;
    float: left;
}
.baz-OrderedProduct p{
    margin: 0;
    padding: 2px 0;
    color: black;
}
@media (max-width: 800px){
    .baz-OrderedProduct{
        width: 90%;
        height: 130px;
    }
}