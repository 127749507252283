.Notification{
    width: 93%;
    height: 80px;
    margin: 2% 4%;
    cursor: pointer;
    border-radius: 5px;
}
.Notification:hover{
    background: rgb(231, 229, 229);
}
.baz-notification-img-container{
    width: 25%;
    height: 90%;
    float: left;
    text-align: center;
    margin-top: 5px;
}
.Notification img{
    width: 70px;
    height: 70px;
    border-radius: 100%;
}
.Notification p{
    width: 68%;
    float: right;
    margin: 0;
    padding: 1.5%;
    color: rgb(54, 54, 54);
}
@media (max-width: 700px){
   
  }