.AdminOrder{
    width: 48%;
    height: 300px;
    background: rgb(255, 255, 255);
    margin: 0 0 30px 0;
    cursor: pointer;
    border-radius: 5px;
}
.AdminOrder:hover{
    box-shadow: 2px 2px 8px 0px #888888;
}
.AdminOrder a{
    margin:0;
    padding: 0 10px;
}
.AdminOrder img{
    width: 70px;
    height: 70px;
    margin: 10px;
}
.baz-ad-AllPro{
    width: 100%;
    height: 170px;
    overflow: hidden;
}